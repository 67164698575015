import React, { createContext, useEffect, useState } from 'react';

const A11yModeContext = createContext();

export const A11yModeContextProvider = ({ children }) => {
  const [isA11yMode, setIsA11yMode] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (e.key === 'Tab') {
        setIsA11yMode(true);
      }
    });
  }, []);

  return (
    <A11yModeContext.Provider value={isA11yMode}>
      {children}
    </A11yModeContext.Provider>
  );
};

export default A11yModeContext;
