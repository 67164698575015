module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#f1dbc7","theme_color":"#f1dbc7","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"docco","path":"/preview","previews":false,"omitPrismicScript":true,"pages":[{"type":"Project","match":"/project/:uid","path":"/project","component":"/vercel/path0/src/templates/Project.js"},{"type":"Category","match":"/category/:uid","path":"/category","component":"/vercel/path0/src/templates/Category.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.docco.co.uk","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
