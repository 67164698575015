import React from 'react';
import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import { linkResolver } from './src/linkResolver';
import { A11yModeContextProvider } from './src/contexts/A11yModeContext';
import { IntersectionObserverContextProvider } from './src/contexts/IntersectionObserverContext';
registerLinkResolver(linkResolver);

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapRootElement = ({ element }) => {
  return (
    <IntersectionObserverContextProvider>
      <A11yModeContextProvider>{element}</A11yModeContextProvider>
    </IntersectionObserverContextProvider>
  );
};
