export const linkResolver = doc => {
  if (doc.type === 'category') {
    return `/category/${doc.uid}`;
  }

  if (doc.type === 'project') {
    return `/project/${doc.uid}`;
  }

  return '/';
};
