import React, { createContext, useEffect, useState } from 'react';

const IntersectionObserverContext = createContext();

export const IntersectionObserverContextProvider = ({ children }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    const imgObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            imgObserver.unobserve(lazyImage);
            lazyImage.src = lazyImage.dataset.src;
          }
        });
      },
      { rootMargin: '100px' }
    );

    setValue({ imgObserver });
  }, []);

  return (
    <IntersectionObserverContext.Provider value={value}>
      {children}
    </IntersectionObserverContext.Provider>
  );
};

export default IntersectionObserverContext;
